<template>
  <div class="exchange-transaction-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" sm="12">
            <h1>{{ $t('EXCHANGE_TRANSACTION_LIST_PAGE_TITLE') }}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchExchangeTransactionHeader :downloadCSV="downloadCSV" :onSearch="onSearch" :statuses="statuses" />

        <ExchangeTransactionListTable :limit="limit" :activePage="activePage" :items="items" :total="total" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import ExchangeTransactionListTable from '@/components/ExchangeTransactions/ExchangeTransactionListTable';
import SearchExchangeTransactionHeader from '@/components/ExchangeTransactions/SearchExchangeTransactionHeader';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';
import { saveAs } from 'file-saver';

export default {
  name: 'ExchangeTransactionList',
  components: {
    ExchangeTransactionListTable,
    SearchExchangeTransactionHeader,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      platformList: [],
      statuses: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      dateFormat: Constants.dateFormat,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    await Promise.all([this.getExchangeTransactionsStatuses()]);
    this.isLoading = false;
  },
  methods: {
    async getExchangeTransactionsStatuses() {
      try {
        const result = await this.$http.get(endpoints.getExchangeTransactionsStatuses);
        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'EXCHANGE_TRANSACTION_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_TRANSACTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getExchangeTransactions(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          email: filterData.email,
          from_date: filterData.dateRange.startDate,
          to_date: dateTimeHelper.adjustToDate(filterData.dateRange.endDate),
          from_currency: filterData.from_currency,
          to_currency: filterData.to_currency,
          status: filterData.status,
          transaction_id: filterData.transaction_id,
        };
        const result = await this.$http.get(endpoints.getExchangeTransactions, { params });

        this.items = result.data.items || [];
        this.items.forEach(item => {
          item.from_currency = item.from_currency.toUpperCase();
          item.to_currency = item.to_currency.toUpperCase();
          item.transaction_date = item.transaction_date || '';
        });
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EXCHANGE_CURRENCY_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getExchangeTransactions();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getExchangeTransactions();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getExchangeTransactions(data);
    },
    async downloadCSV() {
      const filterData = {
        ...this.filterData,
      };
      const params = {
        email: filterData.email,
        from_date: filterData.dateRange.startDate,
        to_date: dateTimeHelper.adjustToDate(filterData.dateRange.endDate),
        from_currency: filterData.from_currency,
        to_currency: filterData.to_currency,
        status: filterData.status,
        transaction_id: filterData.transaction_id,
        timezone_offset: new Date().getTimezoneOffset(),
      };
      try {
        const result = await this.$http.get(endpoints.dowloadCSVExchangeTransaction, { params });
        const data = result;
        const fileUrl = 'exchange-transaction.csv';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.exchange-transaction-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
