var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exchange-transaction-table-container"},[_c('CCard',{staticClass:"exchange-transaction-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage - 1) * _vm.limit))])]}},{key:"transaction_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('a',{staticClass:"btn-link",attrs:{"href":_vm.changellyTrackUrl + item.transaction_id,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.transaction_id))])])]}},{key:"from_currency",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.from_currency))])]}},{key:"to_currency",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.to_currency))])]}},{key:"amount_to",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm._formatAmount(item.amount_to))+" "),(item.status == 'REFUNDED')?_c('span',[_vm._v(_vm._s(item.from_currency))]):_c('span',[_vm._v(_vm._s(item.to_currency))])])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_c('StakingBadge',{attrs:{"type":"exchange-transaction-status","text":item.status}})],1)]}},{key:"transaction_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.transaction_date,_vm.dateFormat)))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,_vm.dateFormat)))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }