<template>
  <div class="exchange-transaction-table-container">
    <CCard class="exchange-transaction-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>
        <template #transaction_id="{item}">
          <td>
            <a :href="changellyTrackUrl + item.transaction_id" class="btn-link" target="_blank" rel="noopener noreferrer">{{ item.transaction_id }}</a>
          </td>
        </template>
        <template #from_currency="{item}">
          <td style="text-align: center">{{ item.from_currency }}</td>
        </template>
        <template #to_currency="{item}">
          <td style="text-align: center">{{ item.to_currency }}</td>
        </template>
        <template #amount_to="{item}">
          <td style="text-align: center">{{ _formatAmount(item.amount_to) }} <span v-if="item.status == 'REFUNDED'">{{ item.from_currency }}</span><span v-else>{{item.to_currency}}</span></td>
        </template>
        <template #status="{item}">
          <td style="text-align: center">
            <StakingBadge type="exchange-transaction-status" :text="item.status" />
          </td>
        </template>
        <template #transaction_date="{item}">
          <td>{{ item.transaction_date | moment(dateFormat) }}</td>
        </template>
        <template #created_at="{item}">
          <td>{{ item.created_at | moment(dateFormat) }}</td>
        </template>
        <template #action="{item}">
          <td width="50px">
            <button class="btn btn-view-details" @click="viewDetails(item)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants, env } from '@/constants';

export default {
  name: 'ExchangeTransactionListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      changellyTrackUrl: env.changellyTrackUrl,
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'transaction_id',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_TX_ID_COL'),
          _style: 'min-width: 120px;',
        },
        {
          key: 'transaction_date',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_TRANSACTION_DATE_COL'),
          _style: 'min-width: 160px;',
        },
        {
          key: 'email',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_EMAIL_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'from_currency',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_FROM_CURRENCY_COL'),
          _style: 'min-width: 140px; text-align: center;',
        },
        {
          key: 'to_currency',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_TO_CURRENCY_COL'),
          _style: 'min-width: 120px;text-align: center;',
        },
        {
          key: 'amount_to',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_AMOUNT_COL'),
          _style: 'text-align:center; min-width: 140px;',
        },
        {
          key: 'status',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_STATUS_COL'),
          _style: 'text-align:center',
        },
        {
          key: 'action',
          label: this.$t('EXCHANGE_TRANSACTION_LIST_TABLE_COMPONENT_ACTION_COL'),
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`transactions/${item.id}/details`);
    },
    _formatAmount(value) {
      if (!value) {
        return 0;
      }
      var formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 5,
        minimumFractionDigits: 0 }).format(value);
      return formatter;
    }
  },
};
</script>

<style lang="scss">
.exchange-transaction-table-container {
  .exchange-transaction-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .currency-image {
        width: 30px;
      }

      .fixed-rate-icon {
        color: #5da30b;
      }
    }
  }
}
</style>
